// Detect IE

window.device = {
	browser: '',
	version: false,
	classList: ''
};

(function (window, document, undefined) {

	// IE Detection
	/**
  * Get the version of Internet Explorer (IE) running or false if not IE.
  * @return {Number|false}
  */
	function getIeVersion() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		// other browser
		return false;
	}

	var ieVersion = getIeVersion();
	if (ieVersion) {
		device.browser = 'IE';
		device.version = ieVersion;
		device.classList += ' is-ie is-ie-' + ieVersion;
	}

	// Any other tests needed here


	device.classList = device.classList.trim();

	if (device.classList) {
		// Required to split strings and add them separately to avoid IE 11s InvalidCharacterError
		device.classList.split(' ').forEach(function (str) {
			document.documentElement.classList.add(str);
		});
	}
})(window, document);
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-history-objectfit-mq-prefixes-setclasses !*/
!function (e, n, t) {
  function r(e, n) {
    return (typeof e === "undefined" ? "undefined" : _typeof(e)) === n;
  }function o() {
    var e, n, t, o, i, s, a;for (var l in C) {
      if (C.hasOwnProperty(l)) {
        if (e = [], n = C[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) {
          e.push(n.options.aliases[t].toLowerCase());
        }for (o = r(n.fn, "function") ? n.fn() : n.fn, i = 0; i < e.length; i++) {
          s = e[i], a = s.split("."), 1 === a.length ? Modernizr[a[0]] = o : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = o), g.push((o ? "" : "no-") + a.join("-"));
        }
      }
    }
  }function i(e) {
    var n = _.className,
        t = Modernizr._config.classPrefix || "";if (w && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");n = n.replace(r, "$1" + t + "js$2");
    }Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), w ? _.className.baseVal = n : _.className = n);
  }function s() {
    return "function" != typeof n.createElement ? n.createElement(arguments[0]) : w ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
  }function a() {
    var e = n.body;return e || (e = s(w ? "svg" : "body"), e.fake = !0), e;
  }function l(e, t, r, o) {
    var i,
        l,
        f,
        u,
        d = "modernizr",
        c = s("div"),
        p = a();if (parseInt(r, 10)) for (; r--;) {
      f = s("div"), f.id = o ? o[r] : d + (r + 1), c.appendChild(f);
    }return i = s("style"), i.type = "text/css", i.id = "s" + d, (p.fake ? p : c).appendChild(i), p.appendChild(c), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(n.createTextNode(e)), c.id = d, p.fake && (p.style.background = "", p.style.overflow = "hidden", u = _.style.overflow, _.style.overflow = "hidden", _.appendChild(p)), l = t(c, e), p.fake ? (p.parentNode.removeChild(p), _.style.overflow = u, _.offsetHeight) : c.parentNode.removeChild(c), !!l;
  }function f(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase();
    }).replace(/^-/, "");
  }function u(e, n) {
    return !!~("" + e).indexOf(n);
  }function d(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }function c(e, n, t) {
    var o;for (var i in e) {
      if (e[i] in n) return t === !1 ? e[i] : (o = n[e[i]], r(o, "function") ? d(o, t || n) : o);
    }return !1;
  }function p(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }function m(n, t, r) {
    var o;if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, n, t);var i = e.console;if (null !== o) r && (o = o.getPropertyValue(r));else if (i) {
        var s = i.error ? "error" : "log";i[s].call(i, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];return o;
  }function v(n, r) {
    var o = n.length;if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) {
        if (e.CSS.supports(p(n[o]), r)) return !0;
      }return !1;
    }if ("CSSSupportsRule" in e) {
      for (var i = []; o--;) {
        i.push("(" + p(n[o]) + ":" + r + ")");
      }return i = i.join(" or "), l("@supports (" + i + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == m(e, null, "position");
      });
    }return t;
  }function h(e, n, o, i) {
    function a() {
      d && (delete N.style, delete N.modElem);
    }if (i = r(i, "undefined") ? !1 : i, !r(o, "undefined")) {
      var l = v(e, o);if (!r(l, "undefined")) return l;
    }for (var d, c, p, m, h, y = ["modernizr", "tspan", "samp"]; !N.style && y.length;) {
      d = !0, N.modElem = s(y.shift()), N.style = N.modElem.style;
    }for (p = e.length, c = 0; p > c; c++) {
      if (m = e[c], h = N.style[m], u(m, "-") && (m = f(m)), N.style[m] !== t) {
        if (i || r(o, "undefined")) return a(), "pfx" == n ? m : !0;try {
          N.style[m] = o;
        } catch (g) {}if (N.style[m] != h) return a(), "pfx" == n ? m : !0;
      }
    }return a(), !1;
  }function y(e, n, t, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
        a = (e + " " + P.join(s + " ") + s).split(" ");return r(n, "string") || r(n, "undefined") ? h(a, n, o, i) : (a = (e + " " + O.join(s + " ") + s).split(" "), c(a, n, t));
  }var g = [],
      C = [],
      S = { _version: "3.6.0", _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 }, _q: [], on: function on(e, n) {
      var t = this;setTimeout(function () {
        n(t[e]);
      }, 0);
    }, addTest: function addTest(e, n, t) {
      C.push({ name: e, fn: n, options: t });
    }, addAsyncTest: function addAsyncTest(e) {
      C.push({ name: null, fn: e });
    } },
      Modernizr = function Modernizr() {};Modernizr.prototype = S, Modernizr = new Modernizr(), Modernizr.addTest("history", function () {
    var n = navigator.userAgent;return -1 === n.indexOf("Android 2.") && -1 === n.indexOf("Android 4.0") || -1 === n.indexOf("Mobile Safari") || -1 !== n.indexOf("Chrome") || -1 !== n.indexOf("Windows Phone") || "file:" === location.protocol ? e.history && "pushState" in e.history : !1;
  });var x = S._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];S._prefixes = x;var _ = n.documentElement,
      w = "svg" === _.nodeName.toLowerCase(),
      b = function () {
    var n = e.matchMedia || e.msMatchMedia;return n ? function (e) {
      var t = n(e);return t && t.matches || !1;
    } : function (n) {
      var t = !1;return l("@media " + n + " { #modernizr { position: absolute; } }", function (n) {
        t = "absolute" == (e.getComputedStyle ? e.getComputedStyle(n, null) : n.currentStyle).position;
      }), t;
    };
  }();S.mq = b;var z = "Moz O ms Webkit",
      P = S._config.usePrefixes ? z.split(" ") : [];S._cssomPrefixes = P;var E = function E(n) {
    var r,
        o = x.length,
        i = e.CSSRule;if ("undefined" == typeof i) return t;if (!n) return !1;if (n = n.replace(/^@/, ""), r = n.replace(/-/g, "_").toUpperCase() + "_RULE", r in i) return "@" + n;for (var s = 0; o > s; s++) {
      var a = x[s],
          l = a.toUpperCase() + "_" + r;if (l in i) return "@-" + a.toLowerCase() + "-" + n;
    }return !1;
  };S.atRule = E;var O = S._config.usePrefixes ? z.toLowerCase().split(" ") : [];S._domPrefixes = O;var j = { elem: s("modernizr") };Modernizr._q.push(function () {
    delete j.elem;
  });var N = { style: j.elem.style };Modernizr._q.unshift(function () {
    delete N.style;
  }), S.testAllProps = y;var T = S.prefixed = function (e, n, t) {
    return 0 === e.indexOf("@") ? E(e) : (-1 != e.indexOf("-") && (e = f(e)), n ? y(e, n, t) : y(e, "pfx"));
  };Modernizr.addTest("objectfit", !!T("objectFit"), { aliases: ["object-fit"] }), o(), i(g), delete S.addTest, delete S.addAsyncTest;for (var A = 0; A < Modernizr._q.length; A++) {
    Modernizr._q[A]();
  }e.Modernizr = Modernizr;
}(window, document);
/**
 * Test for <dialog> element support.
 */
(function (window, document, undefined) {
	var supports = 'show' in document.createElement('dialog');
	if (supports) {
		document.documentElement.classList.add('supports-dialog');
	}
})(window, document);